.user-details {
  display: flex;
  flex-direction: column;

  @media (min-width: 1100px) {
    flex-direction: row;
  }

  .account-details {
    @media (min-width: 1100px) {
      flex: 0 0 50%;
    }

    @media (min-width: 1500px) {
      flex: 0 0 33%;
    }

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media (min-width: 1100px) {
      flex-direction: column;
      flex-wrap: nowrap;
    }

    h2 {
      flex: 0 0 100%;

      @media (min-width: 1100px) {
        flex: 0 0 auto;
      }
    }

    .account-details-section {
      flex: 0 0 50%;

      &:nth-last-child(1) {
        flex: 0 0 100%;
      }

      @media (min-width: 1100px) {
        flex: 0 0 auto;
        &:nth-last-child(1) {
          flex: 0 0 auto;
        }
      }

      .email {
        white-space: nowrap;
      }

    }
  }

  .security-questions {
    margin-bottom: 1rem;
    width: 100%;

    dt {
      width: 100%;
      text-align: left;
    }
  }

  .organisation-details {
    @media (min-width: 1100px) {
      flex: 0 0 50%;
    }

    @media (min-width: 1500px) {
      flex: 0 0 66%;
    }

    .table-legend {
      margin: 0;
      font-size: smaller;
    }

    .delegated-admin {
      @include asterisk;
      span {
        font-weight: bold;
      }
    }

    .selfServiceUserOrg {
      margin-bottom: 1rem;
    }

    .noHeader.ui-datatable table thead tr {
      display: none;
    }

    .ui-datatable.borderless thead th,
    .ui-datatable.borderless tbody td,
    .ui-datatable.borderless tbody tr
    .ui-datatable.borderless tbody {
      border: none;
      text-indent: 0rem;
    }

    .ui-datatable.bottom-border-only tbody tr,
    .ui-datatable.bottom-border-only tbody {
      border: none;
      border-bottom: 1px solid lightgrey;
    }

    table {
      width: 100%;
    }

    td, th {
      padding: 0.5rem;
    }

    th {
      text-align: left;
    }

    td {
      border-top: 1px solid lightgrey;
      border-bottom: 1px solid lightgrey;
      vertical-align: top;
      // Indent lines after the first
      padding-left: 1rem;
      text-indent: -1rem;

      &:nth-of-type(1) {
       
      }

      .role-name{
        width: 25%;
      }

    }
  }
}

.machine-reset-password-section {
  margin-top: 1rem;
}

.account-details-title {
  flex-direction: row;

  h2 {
    display: inline-block;
  }

  a {
    margin-left: 0.5rem;
  }
}